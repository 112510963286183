<template>
  <section class="invoice-add-wrapper">
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
        @submit.prevent="handleSubmit(addUpdateItemAction)"
        @reset.prevent="resetForm"
        v-if="bankaccountData != null"
      >
        <b-row style="margin-bottom: 20px">
          <b-col cols="6">
            <h6
              class="section-label text-primary float-left"
              style="
                border-right: 5px solid orange;
                border-bottom: 1px solid orange;
                border-radius: 0px 50px 0px 0px;
                font-size: 18px;
                padding-right: 10px;
                top: 1px;
                width: auto;
              "
            >
              <feather-icon icon="FileAddIcon" size="25" />
              {{ operationTitle }} Compte Bancaire
            </h6>
          </b-col>

          <b-col cols="6">
            <b-button
              v-if="$router.name == 'bankaccount-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'bankaccount-view',
                  params: { id: bankaccountData.id + 1 },
                })
              "
            >
              <span class="d-none d-sm-inline">Suiv.</span>
              <feather-icon icon="ChevronRightIcon" size="12" />
            </b-button>
            <b-button
              v-if="$router.name == 'bankaccount-view'"
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'bankaccount-view',
                  params: { id: bankaccountData.id - 1 },
                })
              "
            >
              <feather-icon icon="ChevronLeftIcon" size="12" />
              <span class="d-none d-sm-inline">Préc.</span>
            </b-button>

            <b-button
              variant="outline-primary"
              class="btn-sm float-right"
              @click="
                $router.push({
                  name: 'bankaccount-list',
                })
              "
            >
              <feather-icon icon="ListIcon" size="12" />
              <span class="d-none d-sm-inline">Liste Comptes Bancaires</span>
            </b-button>
          </b-col>
        </b-row>

        <b-row class="">  

          <!-- bank -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="bank"
              rules="required"
            >
              <b-form-group
                label="Nom de la Banque"
                label-for="bank"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="bank"
                  v-model="bankaccountData.bank"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Trust Marchant Bank"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez le nom de la banque svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>         

          <!-- name -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Nom de compte"
                label-for="name"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="name"
                  v-model="bankaccountData.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: Dynamique des Femmes Juristes"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez le nom de compte svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- number -->
          <b-col cols="6" md="3">
            <validation-provider
              #default="validationContext"
              name="number"
              rules="required"
            >
              <b-form-group
                label="N° de compte"
                label-for="number"
                :state="getValidationState(validationContext)"
              >
                <b-form-input
                  id="number"
                  v-model="bankaccountData.number"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Ex: 20000158645"
                  class=""
                />
                <b-form-invalid-feedback>
                  Saisissez le N° de compte svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="6" md="3">
            <!-- currency -->
            <validation-provider
              #default="validationContext"
              name="currency"
              rules="required"
            >
              <b-form-group
                label="Devise"
                label-for="currency"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="bankaccountData.currency"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="currencyOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="currency"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner Devise de la Monnaie svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12" md="6">
            <!-- mandators -->
            <validation-provider
              #default="validationContext"
              name="mandators"
              rules="required"
            >
              <b-form-group
                label="Mandataires(s) du compte"
                label-for="mandators"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="bankaccountData.mandators"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="agentOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="mandators"
                  multiple
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  Sélectionner Mandataires svp.
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- numero de compte -->
          <b-col cols="12" md="6"> 
              <b-form-group
                label="Notes"
                label-for="notes" 
              >
                <b-form-input
                  id="notes"
                  v-model="bankaccountData.notes" 
                  trim
                  placeholder="Ex: Compte bancaire pour les finances de l'organisation"
                  class=""
                /> 
              </b-form-group> 
          </b-col>

           
        </b-row>

        <!-- Spacer -->
        <hr class="invoice-spacing" />

        <b-row style="margin-bottom: 20px" v-if="showed == true">
          <b-col cols="12">
            <b-button
              ref="refSubmitEl"
              type="submit"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon icon="SaveIcon" v-if="loading == false" size="19" />

              {{ actionName }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              @click="reinitForm"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Annuler
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-row style="margin-bottom: 20px" v-if="bankaccountData == null">
      <b-col cols="12">
        <h1>Chargement des données en cours...</h1>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import store from "@/store";
import { required, integer } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
// bankaccounts
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BMedia,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BAvatar,
  BFormFile,
  BMediaAside,
  BTable,
  BCardHeader,
  BCardTitle,
  // Form Validation
  BFormInvalidFeedback,
  BFormDatepicker,
  BMediaBody,
  BImg,
} from "bootstrap-vue";

// alerts

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import invoiceStoreModule from "./storeModule";
import router from "@/router";

const moduleName = "bankaccount";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    flatPickr,
    vSelect,
    BFormDatepicker,
    Logo,
    BMedia,
    BMediaBody,
    BFormFile,
    BMediaAside,
    BImg,
    BAvatar,
    BTable,
    BCardHeader,
    BCardTitle, 

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  data() {
    return {
      operationTitle: "",
      operationIcon: "",
      actionName: "",
      actionIndex: 0,
      showed: true,
      loading: false,
      agentOptions: [],  
    };
  },
  props: {
    bankaccountData: {
      type: Object,
    },
    defaultbankaccountData: {
      type: Object,
    },
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  mixins: [heightTransition],
  async mounted() {
    if (router.currentRoute.name == moduleName + "-add-new") {
      var now = new Date();
      this.sku_bankaccount = "ag-" + now.getTime();

      this.operationTitle = "Nouveau ";
      this.imageActionText = "Ajouter ";
      this.operationIcon = "AddIcon";

      this.actionName = "Enregistrer ";
      this.imageActionText = "Ajouter";
      this.actionIndex = 1;
      this.showed = true;
    }
    if (router.currentRoute.name == moduleName + "-view") {
      this.sku_bankaccount = router.currentRoute.params.id;
      this.operationTitle = "Détails du '";
      this.imageActionText = "?";
      this.operationIcon = "EyeIcon";

      this.actionName = "Visionner";
      this.imageActionText = "?";
      this.actionIndex = 2;
      this.showed = false;
    }

    if (router.currentRoute.name == moduleName + "-edit") {
      this.sku_bankaccount = router.currentRoute.params.id;
      this.operationTitle = "Modification du ";
      this.operationIcon = "EditIcon";
      this.imageActionText = "Modifier";

      this.actionName = "Enregistrer Modifications";
      this.imageActionText = "Modifier";
      this.actionIndex = 3;
      this.showed = true;
    }

    // get agents
    await myApi
      .get(`agent`)
      .then((response) => {
        // default action
        var data = response.data.data;
        console.log(data);

        var i = 0;
        for (i = 0; i < data.length; i++) {
          this.agentOptions.push({
            label:
              data[i].firstname +
              " " +
              data[i].lastname +
              " " +
              data[i].surname,
            value: data[i].sku_agent,
          });
        }
      })
      .catch((error) => {
        //
        console.log(error);
      });
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    calculateRemainingBankaccountSumDays() {
      // ------------------------------
      // ------------------------------
    },
    reinitForm() {
      this.$props.bankaccountData = this.defaultbankaccountData;

      if (router.currentRoute.name == "bankaccount-add-new") {
        // init empty bankaccount data
        // bankaccountData = this.defaultbankaccountData;
      } else {
        // requirement //
        // store
        //   .dispatch(APP_STORE_MODULE_NAME + "/fetchModule", {
        //     id: router.currentRoute.params.id,
        //   })
        //   .then((response) => {
        //     bankaccountData.value = response.data;
        //   })
        //   .catch((error) => {
        //     if (error.response.status === 404) {
        //       bankaccountData.value = undefined;
        //     }
        //   });
      }
      // remove all occurences
      // initialise 1 element
      // this.$props.bankaccountData.steps.push(
      //   JSON.parse(JSON.stringify(this.$props.stepformblankData))
      // );
    },
    async addUpdateItemAction() {
      // ----------------------------------------------------
      let formData = new FormData();
      const defaultActionText = this.actionName;

      let method = "";
      let params = "";

      if (router.currentRoute.name == "bankaccount-add-new") {
        this.actionName = "Enregistrement...";
        method = "post";
      }
      if (router.currentRoute.name == "bankaccount-view") {
        method = "get";
        params = "/" + this.bankaccountData.id;
      }
      if (router.currentRoute.name == "bankaccount-edit") {
        this.actionName = "Modification...";
        method = "put";
        params = "/" + this.bankaccountData.id;
      }

      // finalise form data
      formData = this.$props.bankaccountData;

      await myApi({
        url: "bankaccount" + params,
        method: method,
        data: formData,
      })
        .then((response) => {
          // default action
          this.actionName = defaultActionText;
          if (response.status == 200) {
            this.$swal({
              title: "Fin de l'opération",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showCancelButton: true,
              confirmButtonText: "Afficher Formulaire Vide",
              cancelButtonText: "Afficher Liste ?",
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-outline-primary ml-1",
              },
              buttonsStyling: false,
            }).then((result) => {
              if (result.value) {
                // stay here
                // reinit form
                this.reinitForm();
              } else if (result.dismiss === "cancel") {
                // redirection to list
                router.push({ name: "bankaccount-list" });
              }
            });
          } else {
            this.actionName = defaultActionText;
            this.errors.push(response.data.message);
            this.showToast(
              "Erreur Survenu",
              response.data.message,
              "warning",
              "CheckIcon",
              "top-center",
              4000
            );
          }
        })
        .catch((error) => {
          //
          this.actionName = defaultActionText;

          this.showToast(
            "Erreur Exceptionnelle Survenu",
            error.response.data.message,
            "warning",
            "CheckIcon",
            "top-center",
            5000
          );
        });
    },
    goToPrecItem() {
      router.push({ name: "bankaccount-view" });
    },
    goToNextItem() {
      router.push({ name: "bankaccount-list" });
    },
    goToList() {
      router.push({ name: "bankaccount-list" });
    },
    // alert
    alertAutoClose(header, message, seconds, cssClass) {
      this.$swal({
        title: header,
        html: message,
        timer: seconds,
        customClass: {
          confirmButton: cssClass,
        },
        buttonsStyling: false,
      });
      // "btn btn-primary",
    },
    // bankaccount
    showToast(header, message, variant, icon, position, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: header,
            icon,
            text: message,
            variant,
          },
        },
        {
          position,
          timeout,
        }
      );
    },
  },
  setup() {
    //////////////////////////////////////////////////////////////////////////
    const INVOICE_APP_STORE_MODULE_NAME = "app-bankaccount";

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
      store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME);
    });

    let resetbankaccountData = () => {
      // this.bankaccountData = this.$props.bankaccountData;
    };

    let { refFormObserver, getValidationState, resetForm } = formValidation(
      resetbankaccountData
    );

     const currencyOptions = [
      { label: "Dolars Etats-Unis", value: "USD" },
      { label: "Francs Congolais", value: "FC" },
      { label: "Francs CFA", value: "FCFA" },
      { label: "Shilling Uganda", value: "USH" },
      { label: "Francs Rwandais", value: "RW" },
      { label: "Dirhams Emiratis", value: "AED" },
    ];

    return {
      refFormObserver,
      getValidationState,
      currencyOptions,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>